import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class NavigationService {
  localHistorySize: number = 0

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.localHistorySize += 1
      }
    })
  }

  back(): void {
    this.localHistorySize -= 1
    if (this.localHistorySize > 0) {
      this.location.back()
    } else {
      this.router.navigateByUrl('/')
    }
  }
}
